import React from "react";
import SmallCard from "./SmallCard";
import BankIcon from "../../assets/icons/bank.svg";
import MapIcon from "../../assets/icons/map.svg";
import HeartIcon from "../../assets/icons/heart.svg";
import CaseIcon from "../../assets/icons/case_white.svg";
import BankPhoto from "../../assets/images/RBK_photo.png";
import AwardImage from "../../assets/images/award.png";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Pagination} from "swiper";
import {useTranslation} from "gatsby-plugin-react-i18next";

export default function About(props) {
  const {t} = useTranslation();
  const feature = [
    {
      id: 0,
      icon: <BankIcon />,
      name: 'aboutStableTitle',
      description: 'aboutStableDesc'
    },
    {
      id: 1,
      icon: <MapIcon />,
      name: 'aboutMultiplyTitle',
      description: 'aboutMultiplyDesc'
    },
    {
      id: 2,
      icon: <HeartIcon />,
      name: 'aboutCostTitle',
      description: 'aboutCostDesc'
    },
    {
      id: 3,
      icon: <CaseIcon />,
      name: 'aboutProffTitle',
      description: 'aboutProffDesc'
    },
  ]
  return (
    <div id="about" className="container about" data-aos="fade-up" data-aos-offset="500">
      <h2>{t("aboutTitle")}</h2>
      <div className="about__content">
        <div className="about__content_text">
          <p>{t("aboutText1")}</p>
          <ul>
            <li>{t("aboutText2")}</li>
            <li>{t("aboutText3")}</li>
          </ul>
          <br />
          <p>{t("aboutText4")}</p>
          <br />
          <p>{t("aboutText5")}</p>
          <img src={AwardImage} className="about__content_award" />
        </div>
        <img src={BankPhoto} className="about__content_image" />
      </div>
      <div className="about__list">
        {feature.map((service) => {
          return (
            <SmallCard
              icon={service.icon}
              name={service.name}
              description={service.description}
              key={service.id}
            />
          );
        })}
      </div>
      <div className="about__list-mobile">
        <Swiper
          spaceBetween={16}
          slidesPerView={1}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Pagination]}
          className="mySwiper"
        >
          {feature.map((service) => {
            return (
              <SwiperSlide key={service.id}>
                <SmallCard
                  icon={service.icon}
                  name={service.name}
                  description={service.description}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}
