import React from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";

export default function SmallCard(props) {
  const {t} = useTranslation();
  const { icon, name, description } = props;
  return (
    <div className="small-card" data-aos="fade-up" data-aos-delay="200">
      <div>{icon}</div>
      <h6>{t(name)}</h6>
      <p>{t(description)}</p>
    </div>
  );
}
