import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import SmallCard from "../ui/SmallCard";
import RegistrationIcon from "../../assets/icons/registration.svg";
import VideoIdentificationIcon from "../../assets/icons/videoidentification.svg";
import CardIcon from "../../assets/icons/card.svg";
import CaseIcon from "../../assets/icons/case.svg";
import SwapIcon from "../../assets/icons/swap.svg";
import WalletIcon from "../../assets/icons/wallet.svg";
import SmartphoneIcon from "../../assets/icons/smartphone.svg";
import EllipsisIcon from "../../assets/icons/ellipsis.svg";
import {useTranslation} from "gatsby-plugin-react-i18next";

const services = [
  {
    id: 1,
    icon: <RegistrationIcon />,
    name: "servicesRegistrTitle",
    description:
      "servicesRegistrDesc",
  },
  {
    id: 2,
    icon: <VideoIdentificationIcon />,
    name: "servicesVideoIdentificationTitle",
    description:
      "servicesVideoIdentificationDesc",
  },
  {
    id: 3,
    icon: <CardIcon />,
    name: "servicesCardTitle",
    description:
      "servicesCardDesc",
  },
  {
    id: 4,
    icon: <CaseIcon />,
    name: "servicesCaseTitle",
    description: "servicesCaseDesc",
  },
  {
    id: 5,
    icon: <SwapIcon />,
    name: "servicesSwapTitle",
    description:
      "servicesSwapDesc",
  },
  {
    id: 6,
    icon: <WalletIcon />,
    name: "servicesWalletTitle",
    description:
      "servicesWalletDesc",
  },
  {
    id: 7,
    icon: <SmartphoneIcon />,
    name: "servicesSmartphoneTitle",
    description:
      "servicesSmartphoneDesc",
  },
  {
    id: 8,
    icon: <EllipsisIcon />,
    name: "servicesEllipsisTitle",
    description:
      "servicesEllipsisDesc",
  },
];

export default function Services() {
  const {t} = useTranslation();
  return (
    <div id="services" className="background" data-aos="fade-up">
      <div className="container">
        <div className="services">
          <h2>{t("servicesTitle")}</h2>
          <div className="services__list">
            {services.map((service) => {
              return (
                <SmallCard
                  icon={service.icon}
                  name={service.name}
                  description={service.description}
                  key={service.id}
                />
              );
            })}
          </div>

          <div className="services__list-mobile">
            <Swiper
              spaceBetween={16}
              slidesPerView={1}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination]}
              className="mySwiper"
            >
              {services.map((service) => {
                return (
                  <SwiperSlide key={service.id}>
                    <SmallCard
                      icon={service.icon}
                      name={service.name}
                      description={service.description}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
