import React from "react";
import BenefitsImage from "../../assets/images/benefits.png";
import LicenceIcon from "../../assets/icons/licence.svg";
import FlexibilityIcon from "../../assets/icons/flexibility.svg";
import PiggybankIcon from "../../assets/icons/piggybank.svg";
import SupportIcon from "../../assets/icons/support.svg";
import {useTranslation} from "gatsby-plugin-react-i18next";

const benefits = [
  {
    id: 1,
    icon: <LicenceIcon />,
    title: "benefitsLicenceTitle",
    description:
      "benefitsLicenceDesc",
  },
  {
    id: 2,
    icon: <FlexibilityIcon />,
    title: "benefitsFlexibilityTitle",
    description:
      "benefitsFlexibilityDesc",
  },
  {
    id: 3,
    icon: <PiggybankIcon />,
    title: "benefitsPiggybankTitle",
    description:
      "benefitsPiggybankDesc",
  },
  {
    id: 4,
    icon: <SupportIcon />,
    title: "benefitsSupportTitle",
    description:
      "benefitsSupportDesc",
  },
];

export default function Benefits() {
  const {t} = useTranslation();
  return (
    <div id="benefits" className="container" data-aos="fade-up" data-aos-offset="500">
      <div className="benefits__wrapper">
        <h2>{t("benefitsTitle")}</h2>
        <div className="benefits">
          <div className="benefits__left">
            <ul>
              {benefits.map((benefit) => {
                return (
                  <li key={benefit.id}>
                    <div className="benefits__icon">{benefit.icon}</div>
                    <div>
                      <span>{t(benefit.title)}</span>
                      <p>{t(benefit.description)}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="benefits__right">
            <img src={BenefitsImage} alt="Преимущества готовых сервисов" />
          </div>
        </div>
      </div>
    </div>
  );
}
