import React from "react";
import Lottie from "lottie-react";
import logosAnimation from "../../assets/images/Logos.json";
import {useTranslation} from "gatsby-plugin-react-i18next";

export default function Hero({ handleClick }) {
  const {t} = useTranslation();
  return (
    <div className="hero" data-aos="fade-up">
      <div className="hero__left">
        <h1>{t('heroTitle')}</h1>
        <p>{t('heroDesc')}</p>

        <div className="button-group">
          <button
            className="button button__primary"
            onClick={() => handleClick({ type: "api", isOpen: true })}
          >
            {t('request')}
          </button>
        </div>
      </div>

      <div className="hero__right">
        <Lottie animationData={logosAnimation} loop={true} />
      </div>
    </div>
  );
}
