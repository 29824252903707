import {graphql} from 'gatsby';
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import "../assets/styles/global.scss";
import "aos/dist/aos.css";
import Recaptcha from "react-google-invisible-recaptcha";
import InputMask from "react-input-mask";
import Header from "../components/ui/Header";
import Hero from "../components/blocks/Hero";
import Services from "../components/blocks/Services";
import Partners from "../components/blocks/Partners";
import Idea from "../components/blocks/Idea";
import Benefits from "../components/blocks/Benefits";
import Faq from "../components/blocks/Faq";
import Api from "../components/blocks/Api";
import Footer from "../components/ui/Footer";
import Modal from "../components/ui/Modal";
import SuccessIcon from "../assets/icons/success.svg";
import ErrorIcon from "../assets/icons/error.svg";
import LoadingIcon from "../assets/icons/loading.svg";
import About from "../components/ui/About";
import {Link, useI18next, useTranslation} from 'gatsby-plugin-react-i18next';

const IndexPage = () => {
  const [modal, setModal] = useState({ type: "", isOpen: false });

  const [statusModal, setStatusModal] = useState({
    type: "",
    status: "",
    isOpen: false,
  });

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm();

  const refRecaptcha = React.useRef(null);

  let AOS;

  useEffect(() => {
    const AOS = require("aos");
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 150, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 300, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });

  const onResolved = () => {
    const { fio, phone, sender_email, company_name, description } = getValues();

    const postData = {
      fio,
      phone,
      sender_email,
      company_name,
      description,
      captcha_response: refRecaptcha.current.callbacks.getResponse(),
    };

    axios
      .post(
        "https://arm.bankrbk.kz:30500/opers_service/public/email_sender/submit_application/",
        postData
      )
      .then(() => {
        setLoading(false);
        setModal({ type: "", isOpen: false });
        setStatusModal({ type: "success", status: "200", isOpen: true });
      })
      .catch((err) => {
        setLoading(false);
        setModal({ type: "", isOpen: false });
        if (err.request.status >= 400 && err.request.status < 500) {
          setStatusModal({ type: "error", status: "400", isOpen: true });
        } else if (err.request.status >= 500 && err.request.status <= 510) {
          setStatusModal({ type: "error", status: "500", isOpen: true });
        }
      });
    refRecaptcha.current.callbacks.reset();
  };

  const handleClick = (current) => {
    setModal(current);
  };

  const onSubmit = () => {
    setLoading(true);
    refRecaptcha.current.callbacks.execute();
  };
  const {t} = useTranslation();
  const {languages, changeLanguage} = useI18next();
  return (
    <>
      <Link to="/">
        <title>OpenRBK</title>
      </Link>
      <main>
        <div className="container">
          <Header handleClick={handleClick} />
          <Hero handleClick={handleClick} />
        </div>
        <Services />
        <Benefits />
        <Partners />
        <Idea handleClick={handleClick} />
        <About />
        <Faq />
        <Api handleClick={handleClick} />
        <div className="container">
          <Footer />
        </div>

        <Modal
          title={t("apiFormTitle")}
          isOpen={modal.isOpen}
          handleClose={() => setModal({ isOpen: false })}
        >
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <div className={`form__group ${errors.fio ? "error" : ""}`}>
              <input placeholder={t("apiFormFIO")} {...register("fio", { required: true })} />
              {errors.fio && <span>{t("warnReq")}</span>}
            </div>

            <div className={`form__group ${errors.phone ? "error" : ""}`}>
              <Controller
                control={control}
                rules={{ required: true }}
                name="phone"
                render={({ field: { onChange, onBlur, ref } }) => (
                  <InputMask
                    mask="+7 (999) 999-99-99"
                    onBlur={onBlur}
                    onChange={onChange}
                    inputRef={ref}
                    placeholder={t("apiFormPhone")}
                  />
                )}
              />
              {errors.phone && <span>{t("warnReq")}</span>}
            </div>

            <div className={`form__group ${errors.sender_email ? "error" : ""}`}>
              <input
                placeholder={t("apiFormEmail")}
                {...register("sender_email", {
                  required: true,
                  pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                })}
              />
              {errors.sender_email && errors.sender_email.type === "required" && (
                <span>{t("warnReq")}</span>
              )}
              {errors.sender_email && errors.sender_email.type === "pattern" && (
                <span>{t("warnEmail")}</span>
              )}
            </div>

            <div className={`form__group ${errors.company_name ? "error" : ""}`}>
              <input
                placeholder={t("apiFormCompany")}
                {...register("company_name", { required: true })}
              />
              {errors.company_name && <span>{t("warnReq")}</span>}
            </div>
            <div className={`form__group ${errors.description ? "error" : ""}`}>
              <textarea
                placeholder={t("apiFormDesc")}
                {...register("description", { required: true })}
              />
              {errors.description && <span>{t("warnReq")}</span>}
            </div>

            <button type="submit" className="button button__primary">
              {loading ? <LoadingIcon /> : t("request")}
            </button>
            <Recaptcha
              onResolved={onResolved}
              ref={refRecaptcha}
              sitekey="6LfneCInAAAAAISHNXSMviWcwy8lm3wQ7Z9h7JFz"
            />
          </form>
        </Modal>

        <Modal
          title={statusModal.type === "success" ? t("apiFormSuccessShort") : t("apiFormErrorShort")}
          isOpen={statusModal.isOpen}
          handleClose={() => setStatusModal({ isOpen: false })}
        >
          {statusModal.type === "success" ? (
            <div className="modal__success">
              <div className="modal__icon">
                <SuccessIcon />
              </div>

              <p className="modal__text">
                {t("apiFormSuccess")}
              </p>

              <button
                className="button button__primary"
                onClick={() => setStatusModal({ isOpen: false })}
              >
                Готово
              </button>
            </div>
          ) : (
            <div className="modal__error">
              <div className="modal__icon">
                <ErrorIcon />
              </div>

              <p className="modal__text">
                {statusModal.code === "400"
                  ? t("errorSomething")
                  : t("errorServer")}
              </p>

              <button
                className="button button__primary"
                onClick={() => setStatusModal({ isOpen: false })}
              >
                Закрыть
              </button>
            </div>
          )}
        </Modal>
      </main>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
