import React from "react";
import parse from 'html-react-parser';
import {useTranslation} from "gatsby-plugin-react-i18next";

export default function Card(props) {
  const { tab } = props;

  const {t} = useTranslation();
  return (
    <div className="card">
      <div className="card__left">
        <img src={tab?.image} className="responsive" alt={tab.title} />
      </div>
      <div className="card__right">
        <img src={tab?.logo} alt={tab.title} />
        {parse(t(tab?.text))}
      </div>
    </div>
  );
}
