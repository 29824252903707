import React from "react";
import ApiImage from "../../assets/images/api.png";
import {useTranslation} from "gatsby-plugin-react-i18next";

export default function Api({ handleClick }) {
  const {t} = useTranslation();
  return (
    <div className="background">
      <div className="container">
        <div className="api">
          <div className="api__left">
            <img src={ApiImage} alt="Подключить API" />
          </div>
          <div className="api__right">
            <h2>{t("apiTitle")}</h2>
            <p>{t("apiDesc")}</p>
            <button
              className="button button__primary"
              onClick={() => handleClick({ type: "api", isOpen: true })}
            >
              {t("request")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
