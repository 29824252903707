import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Mousewheel, Pagination } from "swiper";
import Card from "../ui/Card";
import TayyabImage from "../../assets/images/tayyab.png";
import SauAppImage from "../../assets/images/sauapp.png";
import SolvaImage from "../../assets/images/solva.png";
import TayyabLogo from "../../assets/images/tayyab_logo.png";
import SauAppLogo from "../../assets/images/sauapp_logo.png";
import SolvaLogo from "../../assets/images/solva_logo.svg";
import {useTranslation} from "gatsby-plugin-react-i18next";



const tabs = [
  {
    id: 1,
    logo: TayyabLogo,
    title: "Tayyab",
    image: TayyabImage,
    text: "TayyabText",
  },
  {
    id: 3,
    logo: SauAppLogo,
    title: "SauApp",
    image: SauAppImage,
    text: "SauAppText",
  },
  {
    id: 4,
    logo: SolvaLogo,
    title: "Solva",
    image: SolvaImage,
    text: "SolvaText",
  },
];

export default function Partners() {
  const {t} = useTranslation();
  return (
    <div id="examples" className="container" data-aos="fade-up">
      <div className="partners">
        <h2>{t("examplesTitle")}</h2>
        <div className="partners__swiper">
          <Swiper
            direction={"horizontal"}
            slidesPerView={1}
            spaceBetween={30}
            mousewheel={true}
            speed={700}
            breakpoints={{
              1025: {
                direction: "vertical",
              },
            }}
            pagination={{
              clickable: true,
              renderBullet: function (index, className) {
                return '<span class="' + className + '">' + tabs[index].title + "</span>";
              },
            }}
            modules={[Mousewheel, Pagination]}
            className="mySwiper"
          >
            {tabs.map((tab) => {
              return (
                <SwiperSlide key={tab.id}>
                  <Card tab={tab} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
