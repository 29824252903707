import React from "react";
import IdeaImage from "../../assets/images/idea.png";
import IdeaImageMobile from "../../assets/images/idea_mobile.png";
import {useTranslation} from "gatsby-plugin-react-i18next";

export default function Idea({ handleClick }) {
  const {t} = useTranslation();
  return (
    <div id="advantages" className="background" data-aos="fade-up">
      <div className="container">
        <div className="idea">
          <div className="idea__left">
            <h2>{t("ideaTitle")}</h2>
            <p>
              {t("ideaDesc")}
            </p>
            <button
              className="button button__primary"
              onClick={() => handleClick({ type: "api", isOpen: true })}
            >
              {t("request")}
            </button>
          </div>
          <div className="idea__right">
            <img src={IdeaImage} className="idea__image-desktop" alt="Появилась идея?" />
            <img src={IdeaImageMobile} className="idea__image-mobile" alt="Появилась идея?" />
          </div>
        </div>
      </div>
    </div>
  );
}
