import React from "react";
import Logo from "../../assets/icons/logo.svg";
import {useI18next, useTranslation, Link} from "gatsby-plugin-react-i18next";

export default function Header({ handleClick }) {
  const {t} = useTranslation();
  const {languages, changeLanguage, language} = useI18next();
  return (
    <div className="header" data-aos="fade-up">
      <div className="header__left">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className="header__right">
        <div className="button-group">
          <a href="#services" className="header__link">{t('services')}</a>
          <a href="#benefits" className="header__link">{t('benefits')}</a>
          <a href="#examples" className="header__link">{t('examples')}</a>
          <a href="#about" className="header__link">{t('about')}</a>
          <a href="#faq" className="header__link">{t('faq')}</a>
          {languages.map((lng) => (
            <a
              style={{
                textTransform: 'uppercase',
                display: language === lng ? 'none':'block'
              }}
              className="header__link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                changeLanguage(lng);
              }}>
              {lng}
            </a>
          ))}
          <button
            className="button button__primary"
            onClick={() => handleClick({ type: "api", isOpen: true })}
          >
            {t('request')}
          </button>
        </div>
      </div>
      <div className="header__right_mobile">
        {languages.map((lng) => (
          <a
            style={{
              textTransform: 'uppercase',
              display: language === lng ? 'none':'block'
            }}
            className="header__link"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              changeLanguage(lng);
            }}>
            {lng}
          </a>
        ))}
      </div>
    </div>
  );
}
