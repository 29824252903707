import React from "react";
import CloseIcon from "../../assets/icons/close.svg";

export default function Modal(props) {
  const { title, children, isOpen, backdropClick, handleClose } = props;
  return (
    <div
      className={"modal " + (isOpen ? "visible " : "hidden")}
      onClick={backdropClick ? handleClose : () => null}
    >
      <div className="modal__window" onClick={(e) => e.stopPropagation()}>
        <div>
          <span className="modal__title">{title}</span>
          <span className="modal__close" onClick={handleClose}>
            <CloseIcon />
          </span>
        </div>

        <div className="modal__content">{children}</div>
      </div>
    </div>
  );
}
