import React from "react";
import { Link } from "gatsby";
import Logo from "../../assets/icons/logo.svg";
import RBKLogo from "../../assets/images/RBK_logo.svg";
import {useTranslation} from "gatsby-plugin-react-i18next";

export default function Footer() {
  const getYear = () => {
    return new Date().getFullYear();
  };
  const {t} = useTranslation();
  return (
    <div className="footer">
      <div className="footer__left">
        <Link to="/">
          <Logo  />
        </Link>
      </div>
      <div className="footer__right copyright">
        <div className="copyright__text">
          <span>{t("footer")}</span><br />
          <a href="https://bankrbk.kz/">https://bankrbk.kz/</a>
        </div>
        <img src={RBKLogo} class="copyright__logo" />
      </div>
    </div>
  );
}
