import React, { useState } from "react";
import Accordion from "../ui/Accordion";
import {useTranslation} from "gatsby-plugin-react-i18next";

const faq = [
  {
    id: 1,
    position: "01",
    question: "faq1Q",
    answer: "faq1A"
  },
  {
    id: 2,
    position: "02",
    question: "faq2Q",
    answer: "faq2A",
  },
  {
    id: 3,
    position: "03",
    question: "faq3Q",
    answer: "faq3A",
  },
];

export default function Faq() {
  const [clicked, setClicked] = useState(1);

  const handleToggle = (id) => {
    if (clicked === id) {
      return setClicked(0);
    }
    setClicked(id);
  };

  const {t} = useTranslation();
  return (
    <div id="faq" className="container" data-aos="fade-up" data-aos-offset="500">
      <div className="faq">
        <h2>{t("faqTitle")}</h2>
        {faq.map(({ id, position, question, answer }) => (
          <Accordion
            position={position}
            question={question}
            answer={answer}
            active={clicked === id}
            onToggle={() => handleToggle(id)}
            key={id}
          />
        ))}
      </div>
    </div>
  );
}
