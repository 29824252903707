import React from "react";
import PlusIcon from "../../assets/icons/plus.svg";
import CloseIcon from "../../assets/icons/close.svg";
import {useTranslation} from "gatsby-plugin-react-i18next";
import parse from 'html-react-parser';

export default function Accordion(props) {
  const { position, question, answer, onToggle, active } = props;
  const {t} = useTranslation();
  return (
    <div style={{width: "100%"}} className={`accordion ${active ? "active" : ""}`} onClick={onToggle}>
      <div className="accordion__position">{position}</div>
      <div className="accordion__content">
        <span>{t(question)}</span>
        {active ? (
          <div className="accordion__close">
            <CloseIcon />
          </div>
        ) : (
          <div className="accordion__open">
            <PlusIcon />
          </div>
        )}
        <div className={`accordion__answer-wrapper ${active ? "open" : ""}`}>
          <div className="accordion__answer">{parse(t(answer))}</div>
        </div>
      </div>
    </div>
  );
}
